:root {
	--blue: #007bff;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #28a745;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #868e96;
	--gray-dark: #343a40;
	--primary: #007bff;
	--secondary: #868e96;
	--success: #28a745;
	--info: #17a2b8;
	--warning: #ffc107;
	--danger: #dc3545;
	--light: #f8f9fa;
	--dark: #343a40;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
	--breakpoint-xxl: 1600px;
	--font-family-sans-serif: sans-serif;
	--font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}


@media print {
	*,
	*::before,
	*::after {
		text-shadow: none !important;
		box-shadow :none !important;
	}
	a,
	a:visted {
	}
	pre {
		white-space: pre-wrap !important;
	}
	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,
	img {
		page-break-inside: avoid;
	}
	p,
	h2,
	h3 {
		orphans: 3;
		windows: 3;
	}
	h2,
	h3 {
		page-break-after:avoid;
	}
	.badge {
		border: 1px solid #000;
	}
	.table {
		border-collapse: collapse !important;
	}
	.badge {
	}
	
	.table-bordered th,
	.table-bordered td {
		border: 1px solid #ddd !important;
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

@-ms-viewport {
	width: device-width;
}

article, footer, header, nav, section {
	display: block;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}

body {
	margin: 0;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;
	font-size: 14px;
	font-family: sans-serif;
	line-height: 1.88889;
	color: #2d4455;
	background-color: #00416f;
	text-align: left;
}

@media (min-width: 768px) {
	body {
		font-size: 16px;
	}
}

@media (min-width: 1200px) {
	body {
		font-size: 18px;
	}
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid #d7d7d7;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: 500;
	color: #2d4455;
}

h3 {
	orphans: 3;
	widows: 3;
	page-break-after: avoid;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.1;
	color: #2d4455;
	font-size: 36px;
	}

h3 > * {
	display: inline-block;
}

@media (min-width: 768px) {
	h3 {
		font-size: 28px;
	}
}

@media (min-width: 1200px) {
	h3 {
		font-size: 40px;
		line-height: 1.35;
	}
}

h5 {
	font-size: 18px;
	font-weight: 300;
	line-height: 1.45;
}

@media (min-width: 768px) {
	h5 {
		font-size: 20px;
	}
}

@media (min-width: 1200px) {
	h5 {
		font-size: 24px;
		line-height: 1.54167;
	}
}

img {
	display:block;
	vertical-align: middle;
	border-style: none;
	page-break-inside: avoid;
	display: inline-block;
	max-width: 100%;
	height: auto;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

p.in {
	color: #2d4455;
	font-size: 18px;
	opacity: 1;
}

p.in2 {
	color: #ffffff;
	font-size: 18px;
	opacity: 1;
}

ul {
	margin-top: 0;
	margin-bottom: 1rem;
}

a {
	color: #00416f;
	display: inline-block;
	text-decoration: none;
	transition: .33s all ease;
}

a, a:active, a:focus {
	color: #00416f;
}

a:hover {
	color: #b1c8d8;
	text-decoration: none;
}

a.view {
	color: #00416f;
	display: inline-block;
	text-decoration: none;
	transition: .33s all ease;
}

a.view, a.view:active, a.view:focus {
	color: #00416f;
}

a.view:hover {
	color: #ffffff;
	text-decoration: none;
}

b {
	font-weight: bolder;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

ul li{
	display: block;
}

/* Colors
================================================== */
.page .text-primary {
	color: #2ed3ae;
}

.page .text-dark {
	color: #333;
}

/* App
================================================== */
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  /* flex-direction: column;
  align-items: right;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Main Layout
================================================== */
.page {
	position: relative;
	overflow: hidden;
	min-height: 100vh !important;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

/* Header
================================================== */
.header {
	background: #00416f;
	z-index: 1000;
	padding: 0;
	margin: 0;
	border-bottom: none;
}

/* Navigation Bar
================================================== */
.navbar { 
	display: block;
	background-color: #00416f;
	/* box-shadow: 0 2px 12px rgba(136, 136, 136, 0.1); */
	width:100%;
	justify-content: space-evenly;
}


.nav {
	float:right;
	margin:0;
	padding:0;
	list-style-type: none;
	background:none;
}

.nav li {
	display: inline;
	padding-right: 5px;
}
	
.wrap {
	padding-top:30px;
	padding-right:15px;
	z-index: 100;
}

.navbar .nav > li > a {
	color: #b1c8d8;
	font-size: 12px;
	line-height: 1.28;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: .05em;
}

.navbar .nav > li > a:hover {
	color: #ffffff;
}

.navbar .nav > li > a.active {
	color: #ffffff;
}

.navbar .nav > li {
	vertical-align: top;
	text-align: center;
}

.navbar .nav > li > * {
	vertical-align: top;
}

.clear {
	clear:both;
}

/* Section
================================================== */
.padding {
	padding-top: 20px;
	padding-bottom: 20px;
}

@media (max-width: 767px) {
	.padding:first-of-type {
		padding-top: 40px;
	}
}

@media (min-width: 768px) {
	.padding {
		padding-top: 95px;
		padding-bottom: 95px;
	}
}

@media (min-width: 992px) {
	.padding {
		padding-top: 130px;
		padding-bottom: 130px;
	}
}

@media (min-width: 1200px) and (min-height: 800px) {
	.padding {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.background {
	background-color: #b1c8d8 !important;
}

.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

@media (min-width: 1600px) {
	.container {
		max-width: 1400px;
	}
}

@media (min-width: 2346px) {
	.container {
		max-width: 2330px;
	}
}

.contentcenter {
	display: inline-block;
	-webkit-align-items: center!important;
	-ms-flex-align: center!important;
	align-items: center!important;
	-webkit-justify-content: center!important;
	-ms-flex-pack: center!important;
	justify-content: center!important;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
	-webkit-align-items: center!important;
	-ms-flex-align: center!important;
	align-items: center!important;
	-webkit-justify-content: center!important;
	-ms-flex-pack: center!important;
	justify-content: center!important;
}

html.lt-ie-10 * + .row,
* + .row {
	margin-top: 25px;
}

@media (min-width: 768px) {
	html.lt-ie-10 * + .row,
	* + .row {
		margin-top: 35px;
	}
}

@media (min-width: 1600px) {
	.row {
		margin-left: -25px;
		margin-right: -25px;
	}
}

html .row-2 {
	margin-bottom: -50px;
}

html .row-2:empty {
	margin-bottom: 0;
}

html .row-2 > * {
	margin-bottom: 50px;
}

.col, .col-2, .col-3, .col-4 {
	position: relative;
	display:flex;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	justify-content:center;
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}
	
.col-2 {
	flex: 0 0 100%;
	max-width: 100%;
}
	
.col-3 {
	flex: 0 0 33.333%;
	max-width: 33.333%;
}

.col-4 {
	flex: 0 0 50%;
	max-width: 50%;
}

.content {
	text-align: left;
}

.content * + .subcontent {
	margin-top: 10px;
}

.content * + .contentbody {
	margin-top: 20px;
}

* + .content {
	margin-top: 50px;
}

@media (min-width: 768px) {
	* + .content {
		margin-top: 60px;
	}
}

* + .contentbody,
.contenttitle + * {
	margin-top: 30px;
}

* + .contenttitle {
	margin-top: 25px;
}

.contenttitle a:hover {
	color: #2ed3ae;
}

.content * + .contentbody {
	margin-top: 20px;
}

.text-center {
	text-align: center !important;
}

.subcontent {
	font-size: 12px;
	font-style: italic;
}

* + .subcontent {
	margin-top: 20px;
}

html .page .subtitle {
	margin-bottom: -5px;
	margin-left: -15px;
}

html .page .subtitle:empty {
	margin-bottom: 0;
	margin-left: 0;
}

html .page .subtitle > * {
	display: inline-block;
	margin-top: 0;
	margin-bottom: 5px;
	margin-left: 15px;
}

.page .content > a {
	color: #00416f;
	font-size: 12px;
	line-height: 1.28;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: .05em;
}

.page .content > a:hover {
	color: #b1c8d8;
}

/* Form
================================================== */
form {
	margin-bottom: 0;
}

label {
	display: inline-block;
	margin-bottom: .5rem;
}

button {
	border-radius: 0;
}

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	width:700px;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}



/* Footer
================================================== */
.footer {
	color: #2d4455;
	font-size: 14px;
	line-height: 1.71429;
	padding: 20px 0;
	text-align: center;
}

@media (min-width: 768px) {
	.footer {
		text-align: left;
	}
	.footer .footer-inner > * + * {
		margin-top: 0;
	}
	.footer-inner {
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin-left: -15px;
		margin-right: -15px;
	}
	.footer-inner > * {
		padding: 0 15px;
	}
}

/* Misc
================================================== */
.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

.table th,
.table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #e9ecef;
}

.table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid #e9ecef;
}

.table tbody + tbody {
	border-top: 2px solid #e9ecef;
}

.center {
		position: absolute;
		top: 0;
		right: 0;
		bottom: -2px;
		left: 0;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		opacity: 0;
		transition: opacity .2s ease-in-out;
		pointer-events: none;
	}